import React from 'react';
import './Password.scss'

import NumpadNum from "../../components/NumpadNum/NumpadNum";
import SetCodeModal from "../../components/SetCodeModal/SetCodeModal";

class Password extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      code: '1234',
			codeLength: 4,
			displayChar: 'X',
			guess: [],
			status: '',
			numEntered: false
    }
  }

	render() {
		return (
			<>
			<section className={`password ${this.state.status}`}>
				<div className="password__container">
					<div className="display">
						{this.getDisplay()}
					</div>

					<div className="numpad">
						<NumpadNum val="1" label="1" numPressed={this.numPressed}/>
						<NumpadNum val="2" label="2" numPressed={this.numPressed}/>
						<NumpadNum val="3" label="3" numPressed={this.numPressed}/>
						<NumpadNum val="4" label="4" numPressed={this.numPressed}/>
						<NumpadNum val="5" label="5" numPressed={this.numPressed}/>
						<NumpadNum val="6" label="6" numPressed={this.numPressed}/>
						<NumpadNum val="7" label="7" numPressed={this.numPressed}/>
						<NumpadNum val="8" label="8" numPressed={this.numPressed}/>
						<NumpadNum val="9" label="9" numPressed={this.numPressed}/>
						<button className="num" disabled={this.state.status !== 'success'} data-toggle="modal" data-target="#setCodeModal" >SET</button>
						<NumpadNum val="0" label="0" numPressed={this.numPressed}/>
						<NumpadNum val=" " label="X" numPressed={this.clear}/>
					</div>
				</div>
			</section>

			<SetCodeModal 
				save={this.setCode}
			/>
			</>
		)
	}

	getDisplay = () => {
		let display = []

		for(let i = 0; i < this.state.codeLength; i++) {
			display.push(<span key={i} className="guess">{ this.state.guess[i] ? this.state.displayChar : '' }</span>)
		}

		return display
	}

	numPressed = num => {
		let newGuess = (this.state.guess.length == this.state.codeLength) ?  [] : [ ...this.state.guess ]

		const shoudCheck = this.state.numEntered && this.state.guess.length == this.state.codeLength - 1

		newGuess.push(num)

		this.setState({
			guess: newGuess,
			numEntered: true,
			status: ''
		}, () => {
    	if (shoudCheck) this.check()
		})
	}

	check = () => {
		this.setState({
			status: (this.state.guess.join('') == this.state.code) ? 'success' : 'fail'
		})
	}

	clear = () => {
		this.setState({
			guess: [],
			numEntered: false,
			status: ''
		})
	}


	setCode = newCode => {
		this.setState({
			code: newCode,
			codeLength: newCode.length,
			guess: [],
			status: '',
			numEntered: false
		})	
	}
}

export default Password;