import React from 'react';
import './Homepage.scss'

class Homepage extends React.Component {
	render() {
		return (
			<section className="homepage">
				<h1>Welcome to UC Services</h1>
			</section>
		)
	}
}

export default Homepage;