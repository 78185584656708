import React from 'react';
import './SetCodeModal.scss';

class SetCodeModal extends React.Component {
	state = {
		code: ''
	}

	handleChange = (e) =>{ 
    this.setState({code: e.target.value});
  }

	render() {
		const { toggle, save, isOpen} = this.props;
		
		return (
			<div className="modal fade set-code-modal" id="setCodeModal" tabIndex="-1" role="dialog" aria-labelledby="setCodeModalLabel">
			  <div className="modal-dialog modal-sm" role="document">
			    <div className="modal-content">
			      <div className="modal-header">
			        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
			        <h4 className="modal-title" id="setCodeModalLabel">Set New Code</h4>
			      </div>
			      <div className="modal-body">
			        <input className="form-control set-code-modal__input" type="number" value={this.state.code} onChange={this.handleChange}/>
			      </div>
			      <div className="modal-footer">
			        <button onClick={ () => save(this.state.code) } data-dismiss="modal" type="button" className="btn btn-primary">Save</button>
			      </div>
			    </div>
			  </div>
			</div>
		)
	}
}

export default SetCodeModal;