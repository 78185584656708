import React from 'react';
import './NumpadNum.scss'

class NumpadNum extends React.Component {
	render() {
		const {val, label, numPressed, disabled, extras = ''} = this.props

		return (
			<button onClick={ () => numPressed(val) } className="num" disabled={disabled}>{label}</button>
		)
	}
}

export default NumpadNum;